<template>
  <div>
    <title>SIM INVENTORY ~ DETAILS PRODUCT LIST DATA</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Product <b style="color:red">{{ produkno }}</b>
        <br />
        <h4>
          The following is a list of transaction data lists, detailed product
          lists
        </h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <!-- <div class="row">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">BOM Data</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize3"
                      @change="handlePageSizeChange3($event)"
                    >
                      <option
                        v-for="size3 in pageSizes3"
                        :key="size3"
                        :value="size3"
                      >
                        {{ size3 }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Item Name"
                      class="form-control"
                      v-model="namesearch3"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata3()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="isExist3" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>I#</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Trash Percent</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(classdata,index3) in list_paged3" :key="classdata.id">
                      <td>{{ index3 + 1 + (page - 1) * pageSize3 }}</td>
                      <td>{{ classdata.kode_barang }}</td>
                      <td>{{ classdata.nama_barang }}</td>
                      <td>{{ classdata.persen_sampah }}</td>
                      <td>{{ classdata.qty }} &nbsp; {{ classdata.unit }}</td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count3"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                       <th>Trash Percent</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="5" class="text-center">
                          <b style="color: red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="row">
        <div class="col-md-12">
          <div class="nav-tabs-custom">
            <ul class="nav nav-tabs pull-right">
              <li>
                <a href="#tab_1-1" data-toggle="tab">BOM</a>
              </li>
              <li class="active">
                <a href="#tab_2-2" data-toggle="tab">Part and Process</a>
              </li>
              <li class="pull-left header">
                <i class="fa fa-th"></i> Product Details
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane" id="tab_1-1">
                <!-- <div class="row">
                  <div class="col-md-12">
                    <div class="box box-primary">
                      <div class="box-header">
                        <div class="box-header with-border">
                          <i class="fa fa-list"></i>
                          <h3 class="box-title">BOM Data</h3>
                        </div>
                        <div class="box-body">
                          <div class="row">
                            <div class="col-xs-5">
                              Show &nbsp; :
                              <select
                                v-model="pageSize3"
                                @change="handlePageSizeChange3($event)"
                              >
                                <option
                                  v-for="size3 in pageSizes3"
                                  :key="size3"
                                  :value="size3"
                                >
                                  {{ size3 }}
                                </option>
                              </select>
                              results
                            </div>
                            <div class="col-xs-5">
                              <input
                                type="text"
                                autocomplete="off"
                                placeholder="Search by Item Name"
                                class="form-control"
                                v-model="namesearch3"
                              />
                            </div>
                            <div class="col-xs-2">
                              <button
                                @click="searchdata3()"
                                type="button"
                                class="btn btn-info"
                              >
                                <i class="fa fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                     
                      <div v-if="isExist3" class="box-body">
                        <div class="table-responsive">
                          <table
                            id="mydata"
                            class="table table-bordered table-striped"
                          >
                            <thead>
                              <tr>
                                <th>I#</th>
                                <th>Item Code</th>
                                <th>Item Name</th>
                                <th>Type</th>
                                <th>Qty</th>
                                <th>Trash Percent</th>
                                
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(classdata, index3) in list_paged3"
                                :key="classdata.id"
                              >
                                <td>
                                  {{ index3 + 1 + (page - 1) * pageSize3 }}
                                </td>
                                <td>{{ classdata.kode_barang }}</td>
                                <td>{{ classdata.nama_barang }}</td>
                                <td>{{ classdata.tipe }}</td>

                                <td>
                                  {{ classdata.qty }} &nbsp;
                                  {{ classdata.unit }}
                                </td>
                                <td v-if="classdata.persen_sampah == ''">
                                  0 &nbsp; %
                                </td>
                                <td v-else>
                                  {{ classdata.persen_sampah }} &nbsp; %
                                </td>
                               
                              </tr>
                            </tbody>
                          </table>
                          <v-pagination
                            v-model="page"
                            :pages="count3"
                            :range-size="1"
                            active-color="#DCEDFF"
                            @update:modelValue="handlePageChange"
                          />
                        </div>
                      </div>
                      <div v-else class="box-body">
                        <div class="table-responsive">
                          <table
                            id="mydata"
                            class="table table-bordered table-striped"
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Item Code</th>
                                <th>Item Name</th>
                                <th>Qty</th>
                                <th>Trash Percent</th>
                                
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colspan="5">
                                  <center>
                                    <b style="color: red">
                                      ... Data Not Found ...
                                    </b>
                                  </center>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div> -->
                <!-- <div class="row">
                  <div class="col-md-12">
                    <div class="col-md-5">
                      <div class="box box-primary">
                        <div class="box-header">
                          <div class="box-header with-border">
                            <i class="fa fa-list"></i>
                            <h3 class="box-title">Get Material Data</h3>
                          </div>
                          <div class="box-body">
                            <div class="form-group">
                              <label for="exampleInputEmail1"
                                >Tipe Barang</label
                              >
                              <select
                                @change="veiwMaterial($event)"
                                class="form-control"
                                v-model="tipebarang"
                                id="tipebarang"
                              >
                                <option disabled>--Please Select--</option>
                                <option
                                  v-for="tipebrg in barangtipe"
                                  :key="tipebrg.tipe"
                                  :value="tipebrg.tipe"
                                >
                                  {{ tipebrg.tipe }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <div class="box box-primary">
                        <div class="box-header">
                          <div class="box-header with-border">
                            <i class="fa fa-list"></i>
                            <h3 class="box-title">BOM List</h3>
                          </div>

                          <div class="box-body">
                            <div class="table-responsive">
                              <table
                                width="100%"
                                id="mydata"
                                class="table table-bordered table-striped"
                              >
                                <thead>
                                  <tr>
                                    <th width="5%">#</th>
                                    <th width="20%">Kode Barang</th>
                                    <th width="25%">Nama Barang</th>
                                    <th width="15%">Qty</th>
                                    <th width="8%">Unit</th>
                                    <th width="8%">Trash Percent</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="classdata in list_paged2"
                                    :key="classdata.ID"
                                  >
                                    <td>
                                      <button
                                        @click="saveData11(classdata)"
                                        type="button"
                                        class="btn btn-primary"
                                      >
                                        <i class="fa fa-save"></i>
                                      </button>
                                    </td>
                                    <td>
                                      <label style="display: none">
                                        {{ classdata.id }}
                                      </label>
                                      {{ classdata.kode_barang }}
                                    </td>
                                    <td>
                                      {{ classdata.nama_barang }}
                                    </td>
                                    <td>
                                      <input
                                        :id="'inp-' + classdata.id"
                                        type="text"
                                        class="form-control"
                                        v-model="classdata.jumlah"
                                        :name="'nm-' + classdata.id"
                                      />
                                    </td>
                                    <td>
                                      {{ classdata.unit }}
                                    </td>
                                    <td>
                                      <input
                                        :id="'inptp-' + classdata.id"
                                        type="text"
                                        class="form-control"
                                        v-model="classdata.trash"
                                        :name="'nmtp-' + classdata.id"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="row">
                  <!-- <div class="col-md-5">
                    <div class="box box-primary">
                      <div class="box-header">
                        <div class="box-header with-border">
                          <i class="fa fa-list"></i>
                          <h3 class="box-title">Get Material Data</h3>
                        </div>
                        <div class="box-body">
                          <div class="form-group">
                            <label for="exampleInputEmail1">Item Type</label>
                            <select
                              @change="veiwMaterial($event)"
                              class="form-control"
                              v-model="tipebarang"
                              id="tipebarang"
                            >
                              <option disabled>--Please Select--</option>
                              <option
                                v-for="tipebrg in barangtipe"
                                :key="tipebrg.tipe"
                                :value="tipebrg.tipe"
                              >
                                {{ tipebrg.tipe }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="col-md-7">
                    <div class="box box-primary">
                      <div class="box-header">
                        <div class="box-header with-border">
                          <i class="fa fa-list"></i>
                          <h3 class="box-title">BOM</h3>
                          <div class="box-body">
                            <div class="table-responsive">
                              <table
                                width="100%"
                                id="mydata"
                                class="table table-bordered table-striped"
                              >
                                <thead>
                                  <tr>
                                    <th width="20%">Item Code</th>
                                    <th width="25%">Item Name</th>
                                    <th width="15%">Type</th>
                                    <th width="8%">Unit</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="classdata in list_paged2"
                                    :key="classdata.ID"
                                  >
                                    <td>
                                      <label style="display: none">
                                        {{ classdata.id }}
                                      </label>
                                      {{ classdata.kode_barang }}
                                    </td>
                                    <td>
                                      {{ classdata.nama_barang }}
                                    </td>
                                    <td>
                                      {{ classdata.tipe }}
                                    </td>
                                    <td>
                                      {{ classdata.unit }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-md-12">
                    <div class="box box-primary">
                      <div class="box-header">
                        <div class="box-header with-border">
                          <i class="fa fa-list"></i>
                          <h3 class="box-title">BOM</h3>
                        </div>
                        <div class="box-body">
                          <div class="row">
                            <div class="col-xs-5">
                              Show &nbsp; :
                              <select
                                v-model="pageSize3"
                                @change="handlePageSizeChange3($event)"
                              >
                                <option
                                  v-for="size3 in pageSizes3"
                                  :key="size3"
                                  :value="size3"
                                >
                                  {{ size3 }}
                                </option>
                              </select>
                              results
                            </div>
                            <div class="col-xs-5">
                              <input
                                type="text"
                                autocomplete="off"
                                placeholder="Search by Item Code"
                                class="form-control"
                                v-model="namesearch3"
                              />
                            </div>
                            <div class="col-xs-2">
                              <button
                                @click="searchdata3()"
                                type="button"
                                class="btn btn-info"
                              >
                                <i class="fa fa-search"></i>
                              </button>
                            </div>
                          </div>
                          <!-- /.box-body -->
                        </div>
                      </div>
                      <div class="box-header">
                        <div class="box-header with-border">
                          <div class="box-body">
                            <div class="table-responsive">
                              <table
                                width="100%"
                                id="mydata"
                                class="table table-bordered table-striped"
                              >
                                <thead>
                                  <tr>
                                    <!-- <th>Product Number</th> -->
                                    <th>Product Name</th>
                                    <th>Item Code</th>
                                    <th>Item Name</th>
                                    <th>Total Needs</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="classdata in list_paged3"
                                    :key="classdata.nomor_produk"
                                  >
                                    <!-- <td>
                                      {{ classdata.nomor_produk }}
                                    </td> -->
                                    <td>
                                      {{ classdata.nama_produk }}
                                    </td>
                                    <td>
                                      {{ classdata.kode_barang }}
                                    </td>
                                    <td>
                                      {{ classdata.nama_barang }}
                                    </td>
                                    <td>
                                      {{ classdata.total_kebutuhan }} &nbsp;
                                      {{ classdata.unit }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <v-pagination
                                v-model="page"
                                :pages="count3"
                                :range-size="1"
                                active-color="#DCEDFF"
                                @update:modelValue="handlePageChange3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.tab-pane -->
              <div class="tab-pane active" id="tab_2-2">
                <div class="row">
                  <div class="col-md-12">
                    <div v-if="addData" class="col-md-4">
                      <div class="box box-primary">
                        <div class="box-header">
                          <div class="box-header with-border">
                            <i class="fa fa-save"></i>
                            <h3 class="box-title">
                              Add Part of
                              <b style="color:red">{{ produkno }}</b>
                            </h3>
                          </div>

                          <form role="form">
                            <div class="box-body">
                              <!-- <div class="row">
                      <div class="col-xs-9">
                        <label for="exampleInputEmail1">Part Code</label>
                        <input
                          type="text"
                          v-model="kodepart"
                          autocomplete="off"
                          class="form-control"
                          placeholder="Part Code"
                        />
                      </div>
                      <div class="col-xs-3">
                        <label for="exampleInputEmail1">Action</label>
                        <br />
                        <button
                          type="button"
                          @click="getpart()"
                          class="btn btn-info"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                    <br /> -->
                              <div class="form-group">
                                <label for="exampleInputEmail1"
                                  >Part Code</label
                                >
                                <input
                                  type="text"
                                  name="pcd"
                                  aria-autocomplete="inline"
                                  v-model="kodepart"
                                  autocomplete="on"
                                  class="form-control"
                                  placeholder="Part Code"
                                />
                              </div>
                              <div class="form-group">
                                <label for="exampleInputEmail1"
                                  >Part Name
                                </label>
                                <input
                                  type="text"
                                  v-model="namapart"
                                  name="pcn"
                                  autocomplete="on"
                                  class="form-control"
                                  placeholder="Part Name"
                                />
                              </div>
                              <!-- <div class="form-group">
                                <label for="exampleInputEmail1">Qty</label>
                                <input
                                  type="number"
                                  v-model="qtyd"
                                  autocomplete="off"
                                  class="form-control"
                                  placeholder="Qty"
                                />
                              </div> -->
                              <div class="form-group">
                                <label for="exampleInputEmail1">Notes</label>
                                <input
                                  type="hidden"
                                  v-model="qtyd"
                                  autocomplete="off"
                                  class="form-control"
                                  placeholder="Qty"
                                />
                                <input
                                  type="text"
                                  v-model="catatand"
                                  autocomplete="off"
                                  class="form-control"
                                  placeholder="Notes"
                                />
                              </div>
                              <div class="form-group">
                                <label for="exampleInputEmail1"> Color</label>
                                <input
                                  type="text"
                                  v-model="warna"
                                  autocomplete="off"
                                  class="form-control"
                                  placeholder="Color"
                                />
                              </div>
                            </div>
                            <div class="box-footer">
                              <button
                                @click="saveData()"
                                type="button"
                                class="btn btn-primary"
                              >
                                <i class="fa fa-save"></i>
                                Save
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="isFrmeditData" class="col-md-4">
                      <div class="box box-primary">
                        <div class="box-header">
                          <div class="box-header with-border">
                            <i class="fa fa-edit"></i>
                            <h3 class="box-title">
                              Update Detailed Product Data
                            </h3>
                          </div>

                          <form role="form">
                            <div class="box-body">
                              <div class="form-group">
                                <label for="exampleInputEmail1"
                                  >Part Code
                                </label>
                                <input
                                  type="hidden"
                                  v-model="idxprodukdno"
                                  autocomplete="off"
                                  class="form-control"
                                />
                                <input
                                  type="text"
                                  readonly
                                  v-model="kodepart"
                                  autocomplete="off"
                                  class="form-control"
                                />
                              </div>
                              <div class="form-group">
                                <label for="exampleInputEmail1"
                                  >Part Name
                                </label>
                                <input
                                  type="text"
                                  readonly
                                  v-model="namapart"
                                  autocomplete="off"
                                  class="form-control"
                                />
                              </div>
                              <!-- <div class="form-group">
                                <label for="exampleInputEmail1">Qty</label>
                                <input
                                  type="number"
                                  v-model="qtyd"
                                  autocomplete="off"
                                  class="form-control"
                                  placeholder="Qty"
                                />
                              </div> -->
                              <div class="form-group">
                                <label for="exampleInputEmail1">Notes</label>
                                <input
                                  type="text"
                                  v-model="catatand"
                                  autocomplete="off"
                                  class="form-control"
                                  placeholder="Notes"
                                />
                              </div>
                              <div class="form-group">
                                <label for="exampleInputEmail1">Color</label>
                                <input
                                  type="text"
                                  v-model="warna"
                                  autocomplete="off"
                                  class="form-control"
                                  placeholder="Color"
                                />
                              </div>
                            </div>
                            <div class="box-footer">
                              <button
                                @click="updateData()"
                                type="button"
                                class="btn btn-primary"
                              >
                                <i class="fa fa-edit"></i>
                                Update
                              </button>
                              &nbsp;
                              <button
                                @click="awaldepan()"
                                class="btn btn-success"
                              >
                                <i class="fa fa-angle-double-left"></i>
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="box box-primary">
                        <div class="box-header">
                          <div class="box-header with-border">
                            <i class="fa fa-list"></i>
                            <h3 class="box-title">
                              List Part of
                              <b style="color:red">{{ produkno }}</b>
                            </h3>
                          </div>
                          <div class="box-body">
                            <div class="row">
                              <div class="col-xs-5">
                                Show &nbsp; :
                                <select
                                  v-model="pageSize"
                                  @change="handlePageSizeChange($event)"
                                >
                                  <option
                                    v-for="size in pageSizes"
                                    :key="size"
                                    :value="size"
                                  >
                                    {{ size }}
                                  </option>
                                </select>
                                results
                              </div>
                              <div class="col-xs-5">
                                <input
                                  type="text"
                                  autocomplete="off"
                                  placeholder="Search by Part Number"
                                  class="form-control"
                                  v-model="namesearch"
                                />
                              </div>
                              <div class="col-xs-2">
                                <button
                                  @click="searchdata()"
                                  type="button"
                                  class="btn btn-info"
                                >
                                  <i class="fa fa-search"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="box-body">
                            <div class="row">
                              <div class="col-xs-3">
                                <label>Product Number</label>
                              </div>

                              <div class="col-xs-1">
                                <label>:</label>
                              </div>
                              <div class="col-xs-7">
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  name="produkno"
                                  v-model="produkno"
                                  id="produkno"
                                />
                              </div>
                            </div>
                          </div> -->
                        </div>

                        <!-- /.box-header -->
                        <div v-if="isExist" class="box-body">
                          <input
                            type="text"
                            class="form-control"
                            readonly
                            name="produkno"
                            v-model="produkno"
                            id="produkno"
                          />
                          <div class="table-responsive">
                            <table
                              id="mydata"
                              class="table table-bordered table-striped"
                            >
                              <thead>
                                <tr>
                                  <!-- <th>Product Number</th> -->
                                  <th>Part No</th>
                                  <th>Part Name</th>
                                  <th>Color</th>
                                  <!--<th>Qty</th> -->
                                  <!--<th>Cycle Time</th> -->
                                  <th>Notes</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="classdata in list_paged"
                                  :key="classdata.id"
                                >
                                  <!-- <td>{{ classdata.id }}</td> -->
                                  <!-- <td>{{ classdata.nomor_produk }}</td> -->
                                  <td>{{ classdata.part_no }}</td>
                                  <td>{{ classdata.part_name }}</td>
                                  <td>{{ classdata.color }}</td>
                                  <!--<td>{{ classdata.qty }}</td> -->
                                  <!--<td>{{ classdata.total_cycle_time }}</td> -->
                                  <td>{{ classdata.notes }}</td>
                                  <td>
                                    <router-link
                                      :to="
                                        '/produk-prosesdetail/' + classdata.id
                                      "
                                    >
                                      <button
                                        @click="setPart(classdata.part_name)"
                                        title="View Process products"
                                        class="btn btn-warning"
                                      >
                                        <i class="fa fa-list"></i>
                                      </button>
                                    </router-link>
                                    <br />
                                    <p></p>
                                    <button
                                      @click="EditData(classdata.id)"
                                      title="Edit User Account"
                                      class="btn btn-info"
                                    >
                                      <i class="fa fa-edit"></i>
                                    </button>
                                    <!--<router-link
                          :to="'/edit-produk-detail/' + classdata.id"
                        >
                          <button
                            title="Edit Product Details"
                            class="btn btn-info"
                          >
                            <i class="fa fa-edit"></i>
                          </button>
                        </router-link>
                        &nbsp; -->
                                    <br />
                                    <p></p>
                                    <button
                                      title="Delete Product List Details"
                                      @click="deleteData(classdata.id)"
                                      class="btn btn-danger"
                                    >
                                      <i class="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <v-pagination
                              v-model="page"
                              :pages="count"
                              :range-size="1"
                              active-color="#DCEDFF"
                              @update:modelValue="handlePageChange"
                            />
                          </div>
                        </div>
                        <div v-else class="box-body">
                          <div class="table-responsive">
                            <table
                              id="mydata"
                              class="table table-bordered table-striped"
                            >
                              <thead>
                                <tr>
                                  <!-- <th>Product Number</th> -->
                                  <th>Part No</th>
                                  <th>Part Name</th>
                                  <th>Color</th>
                                  <!--<th>Qty</th> -->
                                  <!--<th>Cycle Time</th> -->
                                  <th>Notes</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colspan="5">
                                    <center>
                                      <b style="color: red">
                                        ... Data Not Found ...
                                      </b>
                                    </center>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <!-- /.box-body -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- /.tab-pane -->
            </div>
            <!-- /.tab-content -->
          </div>
        </div>
      </div>

      <!--<div class="row">
        <div class="col-md-5">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Get Material Data</h3>
              </div>
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Tipe Barang</label>
                  <select
                    @change="veiwMaterial($event)"
                    class="form-control"
                    v-model="tipebarang"
                    id="tipebarang"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="tipebrg in barangtipe"
                      :key="tipebrg.tipe"
                      :value="tipebrg.tipe"
                    >
                      {{ tipebrg.tipe }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">BOM</h3>
                <div class="box-body">
                  <div class="table-responsive">
                    <table
                      width="100%"
                      id="mydata"
                      class="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th width="20%">Kode Barang</th>
                          <th width="25%">Nama Barang</th>
                          <th width="15%">Type</th>
                          <th width="8%">Unit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="classdata in list_paged2"
                          :key="classdata.ID"
                        >
                          <td>
                            <label style="display: none">
                              {{ classdata.id }}
                            </label>
                            {{ classdata.kode_barang }}
                          </td>
                          <td>
                            {{ classdata.nama_barang }}
                          </td>
                          <td>
                            {{ classdata.tipe }}
                          </td>
                          <td>
                            {{ classdata.unit }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div v-if="addData" class="col-md-5">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-save"></i>
                <h3 class="box-title">Save Detailed Product Data</h3>
              </div>

              <form role="form">
                <div class="box-body">
                  <div class="row">
                      <div class="col-xs-9">
                        <label for="exampleInputEmail1">Part Code</label>
                        <input
                          type="text"
                          v-model="kodepart"
                          autocomplete="off"
                          class="form-control"
                          placeholder="Part Code"
                        />
                      </div>
                      <div class="col-xs-3">
                        <label for="exampleInputEmail1">Action</label>
                        <br />
                        <button
                          type="button"
                          @click="getpart()"
                          class="btn btn-info"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                    <br /> 
                  <div class="form-group">
                    <label for="exampleInputEmail1">Part Code</label>
                    <input
                      type="text"
                      v-model="kodepart"
                      class="form-control"
                      placeholder="Part Code"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Part Name </label>
                    <input
                      type="text"
                      v-model="namapart"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Qty</label>
                    <input
                      type="number"
                      v-model="qtyd"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Qty"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Notes</label>
                    <input
                      type="text"
                      v-model="catatand"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Notes"
                    />
                  </div>
                </div>
                <div class="box-footer">
                  <button
                    @click="saveData()"
                    type="button"
                    class="btn btn-primary"
                  >
                    <i class="fa fa-save"></i>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div v-else-if="isFrmeditData" class="col-md-5">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-edit"></i>
                <h3 class="box-title">Update Detailed Product Data</h3>
              </div>

              <form role="form">
                <div class="box-body">
                  <div class="row">
                    <div class="col-xs-9">
                      <label for="exampleInputEmail1">Part Code</label>
                      <input
                        type="hidden"
                        v-model="idxprodukdno"
                        autocomplete="off"
                        class="form-control"
                      />
                      <input
                        type="text"
                        v-model="kodepart"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Part Code"
                      />
                    </div>
                    <div class="col-xs-3">
                      <label for="exampleInputEmail1">Action</label>
                      <br />
                      <button
                        type="button"
                        @click="getpart()"
                        class="btn btn-info"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <br />
                  <div class="form-group">
                    <label for="exampleInputEmail1">Part Name </label>
                    <input
                      type="text"
                      readonly
                      v-model="namapart"
                      autocomplete="off"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Qty</label>
                    <input
                      type="number"
                      v-model="qtyd"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Qty"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Notes</label>
                    <input
                      type="text"
                      v-model="catatand"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Notes"
                    />
                  </div>
                </div>
                <div class="box-footer">
                  <button
                    @click="updateData()"
                    type="button"
                    class="btn btn-primary"
                  >
                    <i class="fa fa-edit"></i>
                    Update
                  </button>
                  &nbsp;
                  <button @click="awaldepan()" class="btn btn-success">
                    <i class="fa fa-angle-double-left"></i>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="col-md-7">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">List Detailed Data</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Product Number"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-3">
                    <label>Product Number</label>
                  </div>

                  <div class="col-xs-1">
                    <label>:</label>
                  </div>
                  <div class="col-xs-7">
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      name="produkno"
                      v-model="produkno"
                      id="produkno"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Part No</th>
                      <th>Part Name</th>
                      <th>Qty</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="classdata in list_paged" :key="classdata.id">
                      <td>{{ classdata.part_no }}</td>
                      <td>{{ classdata.part_name }}</td>
                      <td>{{ classdata.qty }}</td>
                      <td>{{ classdata.notes }}</td>
                      <td>
                        <router-link
                          :to="'/produk-prosesdetail/' + classdata.id"
                        >
                          <button
                            title="View Process products"
                            class="btn btn-warning"
                          >
                            <i class="fa fa-list"></i>
                          </button>
                        </router-link>
                        &nbsp;
                        <button
                          @click="EditData(classdata.id)"
                          title="Edit User Account"
                          class="btn btn-info"
                        >
                          <i class="fa fa-edit"></i>
                        </button>
                        <br />
                        <p></p>
                        <button
                          title="Delete Product List Details"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Item Code</th>
                      <th>Qty</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="4" class="text-center">
                          <b style="color: red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      -->
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination
  },
  data() {
    return {
      isExist: true,
      isExist3: true,
      isShow: false,
      loading: false,
      namesearch: "",
      namesearch3: "",
      addData: true,
      isFrmeditData: false,
      list_paged: [],
      list_paged2: [],
      list_paged3: [],
      barangtipe: [],
      page: 1,
      pagestat: 1,
      tipebarang: "",
      count: 1,
      count3: 1,
      pageSize: 10,
      pageSize3: 10,
      produkno: "",
      classdata: {
        id: "",
        kode_barang: "",
        nama_barang: "",
        jumlah: "",
        catatan: "",
        unit: "",
        trash: ""
      },
      idxprodukdno: "",
      kodepart: "",
      namapart: "",
      qtyd: "1",
      warna: "",
      catatand: "",
      pageSizes: [10, 50, 100, 500],
      pageSizes3: [10, 50, 100, 500]
    };
  },
  created() {
    this.getbarangtipe();
    this.produkno = this.$route.params.id;
    localStorage.bomproduct = this.produkno;
    this.fetchData();
    this.fetchDataBOM();
    // this.fetchData3();
  },
  methods: {
    adddetail() {
      this.isShow = true;
    },
    awaldepan() {
      this.addData = true;
      this.isFrmeditData = false;
      this.idxprodukdno = "";
      this.kodepart = "";
      this.namapart = "";
      this.qtyd = "1";
      // this.dept = "";
      this.catatand = "";
      this.warna = "";
      this.fetchData();
    },
    EditData(id) {
      this.isFrmeditData = true;
      this.addData = false;
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget = this.$apiurl + "produk_d/getproduk_dbyid?id=" + id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.idxprodukdno = resp.data.data.id;
          // this.qtyd = resp.data.data.qty;
          this.kodepart = resp.data.data.part_no;
          this.namapart = resp.data.data.part_name;
          this.catatand = resp.data.data.notes;
          this.warna = resp.data.data.color;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getpart() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/part_component/getpart_componentbypart_no?part_no=" + this.isidata.partnod;
      const urlAPIget =
        this.$apiurl +
        "part_component/getpart_componentbypart_no?part_no=" +
        this.kodepart;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Part Code not found",
              showConfirmButton: false
            });
          } else {
            this.namapart = resp.data.data.name;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // saveData: function(classdata) {
    saveData: function() {
      this.loading = true;
      var nop = this.produkno;
      // var jml = classdata.jumlah;
      // var sat = classdata.unit;

      var kodep = this.kodepart;
      var namap = this.namapart;
      var qtydet = this.qtyd;
      var catat = this.catatand;
      var col = this.warna;

      if (nop == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Product Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kodep == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (namap == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (col == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Color can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const kodeuser = sessionStorage.getItem("kodeuser");
        /* const paramdata = {
          nomor_produk: this.produkno,
          kode_barang: classdata.kode_barang,
          qty: classdata.jumlah,
          notes: "notes yayayay",
          unit: classdata.unit,
          kode_user: kodeuser
        }; */

        var paramdata = {
          nomor_produk: nop,
          part_no: kodep,
          qty: qtydet,
          notes: catat,
          kode_user: kodeuser,
          part_name: namap,
          color: col
        };

        // console.log(paramdata);
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIsavedata = this.$apiurl + "produk_d_bom/saveproduk_d_bom";
        const urlAPIsavedata = this.$apiurl + "produk_d/saveproduk_d";

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.kodepart = "";
              this.namapart = "";
              // this.dept = "";
              this.catatand = "";
              this.warna = "";
              this.loading = false;
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    saveData2: function(classdata) {
      this.loading = true;
      var nop = this.produkno;
      var jml = classdata.jumlah;
      var sat = classdata.unit;

      if (nop == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Product Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (jml == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (sat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          nomor_produk: this.produkno,
          kode_barang: classdata.kode_barang,
          qty: classdata.jumlah,
          notes: "",
          unit: classdata.unit,
          kode_user: kodeuser
        };
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIsavedata = this.$apiurl + "produk_d_bom/saveproduk_d_bom";

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    saveData11(classdata) {
      this.loading = true;
      var kdbrg = classdata.kode_barang;
      var jml = classdata.jumlah;
      var sat = classdata.unit;
      var samp = classdata.trash;

      if (kdbrg == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (jml == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (sat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (samp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Trash Percent can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        var kodeuser = sessionStorage.getItem("kodeuser");
        var paramdata = {
          nomor_produk: this.$route.params.id,
          kode_barang: classdata.kode_barang,
          qty: classdata.jumlah,
          notes: "",
          unit: classdata.unit,
          kode_user: kodeuser,
          persen_sampah: classdata.trash
        };
        // console.log(paramdata)
        // console.log(kodeuser)
        // var paramdata = {
        //   id_produk_d_proses: this.isidata.idxprodukprosesd,
        //   kode_barang: classdata.kode_barang,
        //   unit: classdata.unit,
        //   kode_user: kodeuser,
        //   qty: classdata.jumlah,
        //   persen_sampah: classdata.trash,
        // };

        // console.log(paramdata);
        var tokenlogin = sessionStorage.getItem("token");
        var urlAPIsavedata =
          // this.$apiurl + "produk_d_proses_bom/saveproduk_d_proses_bom";
          this.$apiurl + "produk_d_bom/saveproduk_d_bom";

        var headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var kodep = this.kodepart;
      var namap = this.namapart;
      var catatanp = this.catatand;
      var idx = this.idxprodukdno;
      var col = this.warna;
      //   var sat = this.satuand;
      if (kodep == "" || namap == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Name / Part No. can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatanp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (col == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Color can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          nomor_produk: this.produkno,
          part_no: this.kodepart,
          qty: this.qtyd,
          notes: this.catatand,
          kode_user: kodeuser,
          part_name: this.namapart,
          color: this.warna
        };

        console.log(paramdata);
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIUpdate = "http://26.183.23.191/inventory/backend/inventory/api/produk_d/updateproduk_d/"+ idx;
        // const urlAPIUpdate = this.$apiurl + "produk_d_bom/updateproduk_d_bom/" + idx;
        const urlAPIUpdate = this.$apiurl + "produk_d/updateproduk_d/" + idx;
        console.log(urlAPIUpdate);
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({
                name: "produkdetail",
                params: { id: this.produkno }
              });
              this.fetchData();
              this.addData = true;
              this.idxprodukdno = "";
              this.kodepart = "";
              this.namapart = "";
              this.warna = "";
              this.catatand = "";
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },
    handlePageChange3(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      // this.fetchData3();
      this.fetchDataBOM();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },
    handlePageSizeChange3(event) {
      this.pageSize3 = event.target.value;
      this.page = 1;
      // this.fetchData3();
      this.fetchDataBOM();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    veiwMaterial(event) {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const brgtype = event.target.value;
      const urlAPIget =
        this.$apiurl +
        "barang/getalldatabarang_by_tipe?length=1000&page=1&tipe=" +
        brgtype;

      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          //   console.log(resp);
          this.list_paged2 = resp.data.data;
          // this.count = resp.data.datatotalcount;
          //   console.log(this.count);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    getbarangtipe() {
      this.loading = true;
      const urlAPIget =
        this.$apiurl + "kategori_barang/getkategori_barang_list_tipe";
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.barangtipe = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/getproduk_hbyid?id=" + this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      const urlApiGETdetail =
        this.$apiurl +
        // "produk_d_bom/getalldataproduk_d_bom?length=" +
        "produk_d/getalldataproduk_d?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&nomor_produk=" +
        this.$route.params.id;
      axios
        .get(urlApiGETdetail, { headers })
        .then((respdetail) => {
          // console.log(respdetail)
          if (respdetail.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = respdetail.data.data;
            this.count = respdetail.data.datatotalcount;
            sessionStorage.setItem("noprd", this.$route.params.id);
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
      this.loading = false;
    },
    async fetchDataBOM() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      // var tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/getproduk_hbyid?id=" + this.$route.params.id;
      var params = {
        page: this.page,
        length: this.pageSize3,
        nomor_produk: this.$route.params.id
      };
      // const tokenlogin = localStorage.getItem("token");
      var tokenlogin = sessionStorage.getItem("token");
      var urlAPIgetdet =
        this.$apiurl + "produk_d_bom/getallv_kebutuhan_material_per_produk";
      var headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };

      axios.post(urlAPIgetdet, params, { headers }).then((respn) => {
        console.log(respn);
        if (respn.data.data.length == 0) {
          this.isExist3 = false;
        } else {
          this.isExist3 = true;
          this.list_paged3 = respn.data.data;
          this.count3 = respn.data.datatotalcount;
        }
        this.loading = false;
      });

      // var urlApiGETdetail =
      //   this.$apiurl +
      //   // "produk_d_bom/getalldataproduk_d_bom?length=" +
      //   "produk_d/getalldataproduk_d?length=" +
      //   this.pageSize +
      //   "&page=" +
      //   this.page +
      //   "&nomor_produk=" +
      //   this.$route.params.id;
      // axios
      //   .get(urlApiGETdetail, { headers })
      //   .then((respdetail) => {
      //     // console.log(respdetail)
      //     if (respdetail.data.data.length == 0) {
      //       this.isExist = false;
      //     } else {
      //       this.isExist = true;
      //       this.list_paged = respdetail.data.data;
      //       this.count = respdetail.data.datatotalcount;
      //       sessionStorage.setItem("noprd", this.$route.params.id);
      //     }
      //     this.loading = false;
      //   })
      //   .catch((err) => {
      //     swal({
      //       icon: "warning",
      //       title: "Warning",
      //       text: err.response.data,
      //     });
      //   });
      // this.loading = false;
    },
    /* async fetchData3() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/getproduk_hbyid?id=" + this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      const urlApiGETdetail =
        this.$apiurl +
        "produk_d_bom/getalldataproduk_d_bom?length=" +
        // "produk_d/getalldataproduk_d?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&nomor_produk=" +
        this.$route.params.id;
      axios
        .get(urlApiGETdetail, { headers })
        .then((respdetail) => {
          // console.log(respdetail);
          if (respdetail.data.data.length == 0) {
            this.isExist3 = false;
          } else {
            this.isExist3 = true;
            this.list_paged3 = respdetail.data.data;
            this.count3 = respdetail.data.datatotalcount;
            // console.log(this.count3)
            sessionStorage.setItem("noprd", this.$route.params.id);
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    }, */
    searchdata() {
      this.loading = true;
      const params = this.getRequestParams(this.page);
      //const params = this.getRequestParams(this.pagestat);
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIgetdet =
        this.$apiurl +
        //   "produk_d_bom/getalldataproduk_d_bom?length=" +
        "produk_d/getalldataproduk_d?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&cari=" +
        this.namesearch +
        "&nomor_produk=" +
        this.produkno;

      axios
        .get(urlAPIgetdet, {
          params,
          headers: { Authorization: tokenlogin }
        })
        .then((respn) => {
          this.list_paged = respn.data.data;
          this.count = respn.data.datatotalcount;
        });
      this.loading = false;
    },
    /* searchdata3() {
      this.loading = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIgetdet = this.$apiurl + "produk_d_bom/getalldataproduk_d_bom?length=" + this.pageSize + "&page=" + this.page +"&cari=" + this.namesearch3 + "&nomor_produk=" + this.$route.params.id;

      axios
        .get(urlAPIgetdet, {
          params,
          headers: { Authorization: tokenlogin },
        })
        .then((respn) => {
          console.log(urlAPIgetdet);
          if(respn.data.data.length == 0) {
            this.isExist3 = false;
          } else {
            this.isExist3 = true;
            this.list_paged3 = respn.data.data;
            this.count3 = respn.data.datatotalcount;
          }
          this.loading = false;
        });

    }, */
    searchdata3() {
      this.loading = true;
      // const params = this.getRequestParams(this.page);
      // const params = this.getRequestParams(this.pagestat);
      var params = {
        // page: this.page,
        length: this.pageSize3,
        cari: this.namesearch3,
        nomor_produk: this.$route.params.id
      };
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIgetdet =
        this.$apiurl + "produk_d_bom/getallv_kebutuhan_material_per_produk";
      var headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios.post(urlAPIgetdet, params, { headers }).then((respn) => {
        // console.log(respn);
        if (respn.data.data.length == 0) {
          this.isExist3 = false;
        } else {
          this.isExist3 = true;
          this.list_paged3 = respn.data.data;
          this.count3 = respn.data.datatotalcount;
        }
        this.loading = false;
      });
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPIbrgdel =
            // this.$apiurl + "produk_d_bom/hapusproduk_d_bom/" + id;
            this.$apiurl + "produk_d/hapusproduk_d/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  // window.location.href = "/produk";
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              });
            });
        } else {
          this.loading = false;
        }
      });
    },

    setPart(part) {
      localStorage.setItem("bompart", part);
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
